@import "./../../common/styles";

body {
  margin: 0;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none;
}

a {
  text-decoration: none;

  &.color-link {
    color: var(--primaryColor);

    &:hover {
      text-decoration: underline;
    }
  }
}

input:focus {
  outline: 0;
}

.container {
  width: 1000px;
  margin: auto;
}

:root {
  font-size: 14px;
}

/* ***** header ****** */
.top {
  background-color: #222;
  color: #fff;
  font-size: 0.9rem;
  padding: 3px 0;
  .top-wrapper {
    display: flex;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .now-time {
        min-width: 120px;
        padding-right: 10px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .link-wrapper {
        a {
          color: #fff;
          padding: 0 10px;
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
          &:hover,
          &.active {
            color: #fff000;
          }
          &:not(:last-child) {
            border-right: 1px solid #c4c4c4;
          }
        }
      }
      .icon-wrapper {
        .mail {
          position: relative;
          cursor: pointer;
          &:hover .mail-string {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
          .mail-string {
            position: absolute;
            color: #000;
            cursor: default;
            right: 0;
            bottom: -22px;
            background-color: rgba(255, 255, 255, 0.7);
            padding: 3px 7px;
            border-radius: 4px;
            transform: translateY(15px);
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
            opacity: 0;
            visibility: hidden;
            &:before {
              content: "";
              position: absolute;
              top: -10px;
              right: 7px;
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid rgba(255, 255, 255, 0.7);
              border-left: 5px solid transparent;
            }
          }
        }
      }
    }
  }
}

.login {
  background-color: #770000;
  min-height: 35px;
  display: flex;
  align-items: center;

  .login-wrapper {
    display: flex;
    align-items: center;
    .logo img {
      max-height: 85px;
    }

    .login-box {
      display: inline-flex;
      margin-left: auto;
      &.hide {
        display: none;
      }
      input {
        background-color: #341f21;
        border: 1px solid #770a0b;
        color: #fff;
        padding: 6px 10px;
        margin-left: 5px;
        &::placeholder {
          color: #ae868b;
        }
      }
      .btn-forgot {
        font-size: 0.8rem;
        border: 0;
        border-radius: 20px;
        padding: 2px 6px;
        color: #fff000;
        display: flex;
        align-items: center;
      }
      .vcode {
        position: relative;
        .verify-code {
          position: absolute;
          right: 0;
          top: 1px;
          width: 54px;
          height: 26px;
        }
      }
      .login-buttons {
        border: 0;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        width: 95px;
        &.yellow {
          background-image: linear-gradient(#fdcd2f, #ffcc2f, #fefaca);
        }
        &.red {
          background-image: linear-gradient(#9e0f0d, #a9120d, #f72112);
          color: #fff;
        }
      }
    }
    .header-links {
      margin-left: auto;
      display: flex;

      &.hide {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px 0;
        li {
          list-style: none;
          &:not(:last-child) {
            margin-right: 20px;
          }
          a {
            color: #ffeab2;
            white-space: nowrap;
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
              color: #fff000;
            }
          }
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.nav {
  background-color: #ca0000;
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    .menu-button {
      color: #fff;
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 42px;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 0;
      &.hide {
        display: none;
      }
      &:before {
        content: "";
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      &.active,
      &:hover {
        color: #fff000;
        &:before {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: #ff0000;
          transform: skewX(34deg);
          z-index: -1;
        }
      }
    }
  }
}

/* ***** footer ****** */
.upper-footer {
  background: url("../img/uf_bg.jpg") top center repeat fixed #9a0302;
  background-size: cover;
  .upper-footer-wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    .col {
      flex-basis: 100%;
      &:not(:last-child) {
        margin-right: 50px;
      }
      .title-wrapper {
        color: #ffe57e;
        display: flex;
        align-items: center;
        .image {
          margin-right: 5px;
        }
        .words {
          .cn {
            font-size: 1.2rem;
            font-weight: bold;
          }
          .eng {
            color: #fff;
            font-size: 0.85rem;
            text-transform: uppercase;
          }
        }
      }

      .content {
        color: #fff;
        font-size: 0.9rem;
        margin-top: 10px;
        &.service {
          .diamond {
            text-align: center;
          }
          .time-box {
            display: flex;
            .desc {
              div:first-child {
                font-size: 1.1rem;
              }
            }
            .time {
              margin-left: auto;
              font-size: 1.4rem;
              .large {
                font-size: 2.6rem;
              }
            }
          }
        }

        &.abtus {
          text-align: center;
          p {
            text-indent: 15px;
            text-align: justify;
          }
          .scan {
            color: #ffe57e;
            font-size: 2rem;
          }
          .scan-slogan {
            font-size: 1.2rem;
          }
          .qr-wrapper {
            display: flex;
            align-items: baseline;
            justify-content: center;
            margin-top: 7px;
          }
        }

        &.brand {
          div {
            margin-bottom: 5px;
          }
          .twentyfour {
            text-align: center;
          }
          .partner {
            display: flex;
            align-items: center;
            span:first-child {
              border-right: 1px solid #fff;
              font-size: 1.2rem;
              margin-right: 5px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    margin-bottom: 20px;
    span {
      background-color: #500e0c;
      position: absolute;
      left: 0;
      top: 0;
      height: 10px;
      border-radius: 20px;
    }
    &.progress1 {
      span {
        animation: expand1 3s ease-out forwards;
      }
      @keyframes expand1 {
        from {
          width: 0;
        }
        to {
          width: 20%;
        }
      }
    }

    &.progress2 {
      span {
        animation: expand2 3s ease-out forwards;
      }
      @keyframes expand2 {
        from {
          width: 0;
        }
        to {
          width: 50%;
        }
      }
    }
  }
}

.footer {
  background-image: linear-gradient(#ef0000, #ed0000, #e10000, #d00000, #be0000, #a90000, #ab0000, #c70000);
  color: #fff;
  padding: 20px 0;
  .footer-wrapper {
    display: flex;
    align-items: center;
    .copyright {
      font-size: 0.8rem;
    }
    .payment {
      display: flex;
      margin-left: auto;
      .payment-box {
        display: flex;
        align-items: center;
        margin: 0 10px;
        img {
          width: 50px;
        }
      }
    }
  }
}

/* ***** sidebar ****** */
.leftBar {
  position: fixed;
  z-index: 4;
  top: 25%;
  left: 22px;
}

.fixBar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;

  > a {
    position: relative;
    margin-bottom: 5px;
  }

  .fixteam {
    background-image: linear-gradient(#ef0000, #aa0000);
    border: 1px solid #ff0000;
    border-radius: 10px;
    box-sizing: border-box;
    width: 52px;
    height: 52px;
    display: block;
  }
}

.fixteam {
  &:hover .sidebar-desc {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
  }

  .sidebar-desc {
    min-width: 50px;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translate(-30%, -50%);
    right: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    padding: 5px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.8125rem;
    color: #fff;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(0, 0, 0, 0.7);
    }

    img,
    .qr-code {
      display: block;
      max-width: 130px;
    }
  }
}

/* ***** index ****** */
.banner {
  position: relative;
  .swiper-container {
    height: 500px;
    overflow: hidden;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        overflow: hidden;
        .img-bg {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          filter: blur(10px) grayscale(50%);
          z-index: 0;
        }
        img {
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
      }
    }
  }
}

.notice-box {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ab150f;
  height: 40px;
  line-height: 40px;
}

.marquee-wrapper {
  display: flex;
  overflow: hidden;
}

.marquee-box {
  width: 50%;
  position: relative;
  box-sizing: border-box;
  padding-left: 30px;
  background: url(../img/speaker.png) left center no-repeat;
  line-height: 40px;
  height: 40px;
  color: #fff;
  ul {
    overflow: hidden;
    zoom: 1;
    margin: 0;
    li {
      margin-right: 20px;
      float: left;
      height: 40px;
      line-height: 40px;
      text-align: left;
      _display: inline;
      width: auto !important;
      a {
        color: #fff;
      }
    }
  }
  &:not(:first-child) {
    margin-left: 15px;
  }
}

.hotgame {
  background-color: #770000;
  #guestEnabled {
    + .hotgame-wrapper {
      display: none;
    }
  }
  .hotgame-wrapper {
    display: flex;
    align-items: center;
    padding: 40px 0;
    &.hide {
      display: none;
    }
    .sprite-box {
      flex-basis: 100%;
    }
  }
}

/* ***** sprites ****** */
.sprite {
  width: 100px;
  height: 96px;
  margin: auto;
  &.sprite1 {
    background: url("../img/sprites.png") -10px -10px;
  }
  &.sprite2 {
    background: url("../img/sprites.png") -10px -126px;
  }
  &.sprite3 {
    background: url("../img/sprites.png") -130px -10px;
  }
  &.sprite4 {
    background: url("../img/sprites.png") -130px -126px;
  }
  &.sprite5 {
    background: url("../img/sprites.png") -250px -126px;
  }
  &.sprite6 {
    background: url("../img/sprites.png") -250px -10px;
  }
  &.sprite7 {
    background: url("../img/sprites.png") -10px -242px;
  }
}

/* ***** register ****** */
.register {
  background: url("../img/bg.jpg") top center no-repeat fixed #350404;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
  padding-bottom: 10px;

  .welcome {
    img {
      max-width: 100%;
    }
  }

  .register-container {
    background-color: #faeeee;
    border: 5px solid #ca0000;
    margin-top: -3px;
    .reg-steps {
      width: 75%;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #efe8e8;
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .reg-steps-box {
        &.red {
          border: 1px solid #eeb958 !important;
          background: rgb(144, 19, 19);
          background: linear-gradient(to right, rgba(144, 19, 19, 1) 0%, rgba(156, 46, 46, 1) 100%);
        }
        display: inline-block;
        vertical-align: middle;
        width: calc(100% / 3);
        text-align: center;
        border: 1px solid #c2c2c2;
        line-height: 32pt;
        float: left;
        box-sizing: border-box;
        position: relative;
        &:not(:first-child) {
          border-left: 0;
        }
        &:not(:last-child) {
          border-left: 0;
          &:before {
            content: "";
            width: 17px;
            height: 30px;
            position: absolute;
            background-color: #efe8e8;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            top: 6px;
            right: -18px;
            border: 1px solid #c2c2c2;
            border-left: none;
          }
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border-left: 5px solid #978e8e;
            border-top: 6px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 6px solid transparent;
            top: 16px;
            right: -15px;
          }
        }
        &.red:not(:last-child) {
          &:before {
            content: "";
            width: 17px;
            height: 30px;
            position: absolute;
            background-color: #9c2e2e;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            top: 6px;
            right: -17px;
            border: 1px solid #eeb958;
            border-left: none;
          }
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border-left: 5px solid #fff;
            border-top: 6px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 6px solid transparent;
            top: 16px;
            right: -15px;
          }
        }

        .steps-no {
          display: inline-block;
          vertical-align: middle;
          font-size: 18pt;
          color: #978e8e;
        }
        .steps-title {
          display: inline-block;
          vertical-align: middle;
          font-size: 10.5pt;
          color: #978e8e;
        }
        .steps-no.red,
        .steps-title.red {
          color: #fff;
        }
      }
    }

    .register-form {
      padding: 50px 0 30px 0;
      .main {
        width: 100%;
        box-sizing: border-box;
        .row {
          height: 40px;
          text-align: center;
          margin-bottom: 10px;
          .text {
            color: #e72e31;
            float: left;
            width: 200px;
            line-height: 33px;
            text-align: right;
            padding-right: 20px;
            box-sizing: border-box;
            font-size: 14px;
          }
          .inp {
            float: left;
            width: 290px;
            position: relative;
            input {
              display: block;
              width: 290px;
              height: 33px;
              border: 1px solid #978e8e;
              border-radius: 4px;
              background: transparent;
              box-sizing: border-box;
              outline: none;
              text-indent: 10px;
            }
          }
          .tip {
            line-height: 26px;
            display: flex;
            font-size: 12px;
            color: #000;
            padding-left: 20px;
          }

          button[type="submit"] {
            background-image: linear-gradient(#ea4c4c, #c52323);
            border: 0;
            border-radius: 20px;
            color: #fff;
            cursor: pointer;
            font-size: 1rem;
            margin-top: 12px;
            padding: 8px;
            width: 130px;
          }
        }

        .Success {
          .icon img {
            display: block;
            margin: 0 auto;
          }
          h2 {
            line-height: 120px;
            text-align: center;
            font-size: 42px;
            color: #3e3d3d;
          }
          ul {
            text-align: center;
          }
          li {
            display: inline-block;
            color: #648fc5;
            font-size: 16px;
            a {
              color: #648fc5;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

/* ***** app ****** */
.app {
  background: url("../img/app_bg.jpg") top center no-repeat fixed #ca2a1b;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);

  .slogan {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .app-wrapper {
    display: flex;
    align-items: center;
    .ios-col,
    .android-col {
      flex-basis: 16%;
      height: 200px;
      width: 163px;
      position: relative;
      .qr-code-wrapper {
        position: absolute;
        top: 10px;
        text-align: center;
        width: 100%;
      }
    }
    .car-col {
      flex-basis: 60%;
      .img-car-wrapper {
        position: relative;
        .img-car {
          width: 600px;
        }
        .img-car-logo {
          position: absolute;
          left: 282px;
          top: 66px;
          width: 80px;
        }
      }
    }
  }
}

/* ***** faqs ****** */
.faq {
  background: url("../img/bg.jpg") top center no-repeat fixed #350404;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
  .welcome {
    img {
      max-width: 100%;
    }
  }
  .faq-wrapper {
    display: flex;
    margin-top: -3px;
    .faq-menu {
      flex-basis: 15%;
      margin-right: 6px;
      span,
      a {
        background-color: #ca0000;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: block;
        text-align: center;
        margin-bottom: 6px;
        padding: 15px;
        &.active {
          background-color: #ffd76b;
          color: #000;
        }
      }
    }

    .faq-content-box {
      flex-basis: 85%;
      background-color: #ca0000;
      color: #fff;
      margin-bottom: 10px;
      padding: 15px;
      a {
        color: #fff000;
      }
      h2 {
        color: #fff728;
      }
      p {
        margin-top: 0;
        margin-bottom: 5px;
      }
      .relative {
        position: relative;
      }
      .applogo {
        position: absolute;
        border-radius: 6px;
        padding: 2px;
        &.logo1 {
          left: 12px;
          top: 20px;
          height: 36px;
          width: 36px;
        }
        &.logo2 {
          left: 500px;
          top: 142px;
          height: 17px;
          width: 17px;
        }
        &.logo3 {
          left: 14px;
          top: 25px;
          height: 23px;
          width: 23px;
        }
        &.logo4 {
          left: 505px;
          top: 25px;
          height: 23px;
          width: 23px;
        }
      }
      .webname {
        position: absolute;
        color: #000;
        &.name1 {
          color: #6a6a6a;
          font-size: 0.7rem;
          left: 12px;
          top: 60px;
        }
        &.name2 {
          font-size: 0.8rem;
          left: 525px;
          top: 144px;
        }
        &.name3 {
          left: 44px;
          top: 28px;
        }
        &.name4 {
          left: 535px;
          top: 28px;
        }
      }
    }
  }
}

/* ***** promo ****** */
.promo {
  background: url("../img/promo_bg.jpg") top center no-repeat fixed #c80000;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
  padding: 20px 0;
  .welcome {
    text-align: center;
  }
  .promo-wrapper {
    background-color: #ca0000;
    color: #fff;
    padding: 10px;
    margin-top: -3px;
    .icon {
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    a {
      color: #fff000;
    }
  }
}

.cooperation-list {
  display: flex;

  > div {
    text-align: left;
  }

  .cooperation-title {
    flex-basis: 150px;
    font-weight: bold;

    &:after {
      content: ":";
    }
  }

  .cooperation-qrcode {
    width: 150px;
    background: white;
  }
}
