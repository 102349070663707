iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

body {
  margin: 0;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none;
}

a {
  text-decoration: none;
}
a.color-link {
  color: var(--primaryColor);
}
a.color-link:hover {
  text-decoration: underline;
}

input:focus {
  outline: 0;
}

.container {
  width: 1000px;
  margin: auto;
}

:root {
  font-size: 14px;
}

/* ***** header ****** */
.top {
  background-color: #222;
  color: #fff;
  font-size: 0.9rem;
  padding: 3px 0;
}
.top .top-wrapper {
  display: flex;
  align-items: center;
}
.top .top-wrapper .left {
  display: flex;
  align-items: center;
}
.top .top-wrapper .left .now-time {
  min-width: 120px;
  padding-right: 10px;
}
.top .top-wrapper .right {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.top .top-wrapper .right .link-wrapper a {
  color: #fff;
  padding: 0 10px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.top .top-wrapper .right .link-wrapper a:hover, .top .top-wrapper .right .link-wrapper a.active {
  color: #fff000;
}
.top .top-wrapper .right .link-wrapper a:not(:last-child) {
  border-right: 1px solid #c4c4c4;
}
.top .top-wrapper .right .icon-wrapper .mail {
  position: relative;
  cursor: pointer;
}
.top .top-wrapper .right .icon-wrapper .mail:hover .mail-string {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.top .top-wrapper .right .icon-wrapper .mail .mail-string {
  position: absolute;
  color: #000;
  cursor: default;
  right: 0;
  bottom: -22px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 3px 7px;
  border-radius: 4px;
  transform: translateY(15px);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden;
}
.top .top-wrapper .right .icon-wrapper .mail .mail-string:before {
  content: "";
  position: absolute;
  top: -10px;
  right: 7px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(255, 255, 255, 0.7);
  border-left: 5px solid transparent;
}

.login {
  background-color: #770000;
  min-height: 35px;
  display: flex;
  align-items: center;
}
.login .login-wrapper {
  display: flex;
  align-items: center;
}
.login .login-wrapper .logo img {
  max-height: 85px;
}
.login .login-wrapper .login-box {
  display: inline-flex;
  margin-left: auto;
}
.login .login-wrapper .login-box.hide {
  display: none;
}
.login .login-wrapper .login-box input {
  background-color: #341f21;
  border: 1px solid #770a0b;
  color: #fff;
  padding: 6px 10px;
  margin-left: 5px;
}
.login .login-wrapper .login-box input::placeholder {
  color: #ae868b;
}
.login .login-wrapper .login-box .btn-forgot {
  font-size: 0.8rem;
  border: 0;
  border-radius: 20px;
  padding: 2px 6px;
  color: #fff000;
  display: flex;
  align-items: center;
}
.login .login-wrapper .login-box .vcode {
  position: relative;
}
.login .login-wrapper .login-box .vcode .verify-code {
  position: absolute;
  right: 0;
  top: 1px;
  width: 54px;
  height: 26px;
}
.login .login-wrapper .login-box .login-buttons {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 95px;
}
.login .login-wrapper .login-box .login-buttons.yellow {
  background-image: linear-gradient(#fdcd2f, #ffcc2f, #fefaca);
}
.login .login-wrapper .login-box .login-buttons.red {
  background-image: linear-gradient(#9e0f0d, #a9120d, #f72112);
  color: #fff;
}
.login .login-wrapper .header-links {
  margin-left: auto;
  display: flex;
}
.login .login-wrapper .header-links.hide {
  display: none;
}
.login .login-wrapper .header-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
}
.login .login-wrapper .header-links ul li {
  list-style: none;
}
.login .login-wrapper .header-links ul li:not(:last-child) {
  margin-right: 20px;
}
.login .login-wrapper .header-links ul li a {
  color: #ffeab2;
  white-space: nowrap;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.login .login-wrapper .header-links ul li a:hover {
  color: #fff000;
}
.login .login-wrapper .header-links ul li span {
  color: #fff;
}

.nav {
  background-color: #ca0000;
}
.nav .menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav .menu .menu-button {
  color: #fff;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 42px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 0;
}
.nav .menu .menu-button.hide {
  display: none;
}
.nav .menu .menu-button:before {
  content: "";
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.nav .menu .menu-button.active, .nav .menu .menu-button:hover {
  color: #fff000;
}
.nav .menu .menu-button.active:before, .nav .menu .menu-button:hover:before {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ff0000;
  transform: skewX(34deg);
  z-index: -1;
}

/* ***** footer ****** */
.upper-footer {
  background: url("../img/uf_bg.jpg") top center repeat fixed #9a0302;
  background-size: cover;
}
.upper-footer .upper-footer-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}
.upper-footer .upper-footer-wrapper .col {
  flex-basis: 100%;
}
.upper-footer .upper-footer-wrapper .col:not(:last-child) {
  margin-right: 50px;
}
.upper-footer .upper-footer-wrapper .col .title-wrapper {
  color: #ffe57e;
  display: flex;
  align-items: center;
}
.upper-footer .upper-footer-wrapper .col .title-wrapper .image {
  margin-right: 5px;
}
.upper-footer .upper-footer-wrapper .col .title-wrapper .words .cn {
  font-size: 1.2rem;
  font-weight: bold;
}
.upper-footer .upper-footer-wrapper .col .title-wrapper .words .eng {
  color: #fff;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.upper-footer .upper-footer-wrapper .col .content {
  color: #fff;
  font-size: 0.9rem;
  margin-top: 10px;
}
.upper-footer .upper-footer-wrapper .col .content.service .diamond {
  text-align: center;
}
.upper-footer .upper-footer-wrapper .col .content.service .time-box {
  display: flex;
}
.upper-footer .upper-footer-wrapper .col .content.service .time-box .desc div:first-child {
  font-size: 1.1rem;
}
.upper-footer .upper-footer-wrapper .col .content.service .time-box .time {
  margin-left: auto;
  font-size: 1.4rem;
}
.upper-footer .upper-footer-wrapper .col .content.service .time-box .time .large {
  font-size: 2.6rem;
}
.upper-footer .upper-footer-wrapper .col .content.abtus {
  text-align: center;
}
.upper-footer .upper-footer-wrapper .col .content.abtus p {
  text-indent: 15px;
  text-align: justify;
}
.upper-footer .upper-footer-wrapper .col .content.abtus .scan {
  color: #ffe57e;
  font-size: 2rem;
}
.upper-footer .upper-footer-wrapper .col .content.abtus .scan-slogan {
  font-size: 1.2rem;
}
.upper-footer .upper-footer-wrapper .col .content.abtus .qr-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 7px;
}
.upper-footer .upper-footer-wrapper .col .content.brand div {
  margin-bottom: 5px;
}
.upper-footer .upper-footer-wrapper .col .content.brand .twentyfour {
  text-align: center;
}
.upper-footer .upper-footer-wrapper .col .content.brand .partner {
  display: flex;
  align-items: center;
}
.upper-footer .upper-footer-wrapper .col .content.brand .partner span:first-child {
  border-right: 1px solid #fff;
  font-size: 1.2rem;
  margin-right: 5px;
  padding-right: 5px;
}
.upper-footer .progress-bar {
  width: 100%;
  height: 10px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
}
.upper-footer .progress-bar span {
  background-color: #500e0c;
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  border-radius: 20px;
}
.upper-footer .progress-bar.progress1 span {
  animation: expand1 3s ease-out forwards;
}
@keyframes expand1 {
  from {
    width: 0;
  }
  to {
    width: 20%;
  }
}
.upper-footer .progress-bar.progress2 span {
  animation: expand2 3s ease-out forwards;
}
@keyframes expand2 {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

.footer {
  background-image: linear-gradient(#ef0000, #ed0000, #e10000, #d00000, #be0000, #a90000, #ab0000, #c70000);
  color: #fff;
  padding: 20px 0;
}
.footer .footer-wrapper {
  display: flex;
  align-items: center;
}
.footer .footer-wrapper .copyright {
  font-size: 0.8rem;
}
.footer .footer-wrapper .payment {
  display: flex;
  margin-left: auto;
}
.footer .footer-wrapper .payment .payment-box {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.footer .footer-wrapper .payment .payment-box img {
  width: 50px;
}

/* ***** sidebar ****** */
.leftBar {
  position: fixed;
  z-index: 4;
  top: 25%;
  left: 22px;
}

.fixBar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
}
.fixBar > a {
  position: relative;
  margin-bottom: 5px;
}
.fixBar .fixteam {
  background-image: linear-gradient(#ef0000, #aa0000);
  border: 1px solid #ff0000;
  border-radius: 10px;
  box-sizing: border-box;
  width: 52px;
  height: 52px;
  display: block;
}

.fixteam:hover .sidebar-desc {
  transform: translate(0, -50%);
  opacity: 1;
  visibility: visible;
}
.fixteam .sidebar-desc {
  min-width: 50px;
  display: block;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  text-decoration: none;
  position: absolute;
  top: 50%;
  transform: translate(-30%, -50%);
  right: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.8125rem;
  color: #fff;
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.fixteam .sidebar-desc:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
}
.fixteam .sidebar-desc img,
.fixteam .sidebar-desc .qr-code {
  display: block;
  max-width: 130px;
}

/* ***** index ****** */
.banner {
  position: relative;
}
.banner .swiper-container {
  height: 500px;
  overflow: hidden;
}
.banner .swiper-container .swiper-wrapper {
  height: 100%;
}
.banner .swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
}
.banner .swiper-container .swiper-wrapper .swiper-slide .img-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px) grayscale(50%);
  z-index: 0;
}
.banner .swiper-container .swiper-wrapper .swiper-slide img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.notice-box {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ab150f;
  height: 40px;
  line-height: 40px;
}

.marquee-wrapper {
  display: flex;
  overflow: hidden;
}

.marquee-box {
  width: 50%;
  position: relative;
  box-sizing: border-box;
  padding-left: 30px;
  background: url(../img/speaker.png) left center no-repeat;
  line-height: 40px;
  height: 40px;
  color: #fff;
}
.marquee-box ul {
  overflow: hidden;
  zoom: 1;
  margin: 0;
}
.marquee-box ul li {
  margin-right: 20px;
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: left;
  _display: inline;
  width: auto !important;
}
.marquee-box ul li a {
  color: #fff;
}
.marquee-box:not(:first-child) {
  margin-left: 15px;
}

.hotgame {
  background-color: #770000;
}
.hotgame #guestEnabled + .hotgame-wrapper {
  display: none;
}
.hotgame .hotgame-wrapper {
  display: flex;
  align-items: center;
  padding: 40px 0;
}
.hotgame .hotgame-wrapper.hide {
  display: none;
}
.hotgame .hotgame-wrapper .sprite-box {
  flex-basis: 100%;
}

/* ***** sprites ****** */
.sprite {
  width: 100px;
  height: 96px;
  margin: auto;
}
.sprite.sprite1 {
  background: url("../img/sprites.png") -10px -10px;
}
.sprite.sprite2 {
  background: url("../img/sprites.png") -10px -126px;
}
.sprite.sprite3 {
  background: url("../img/sprites.png") -130px -10px;
}
.sprite.sprite4 {
  background: url("../img/sprites.png") -130px -126px;
}
.sprite.sprite5 {
  background: url("../img/sprites.png") -250px -126px;
}
.sprite.sprite6 {
  background: url("../img/sprites.png") -250px -10px;
}
.sprite.sprite7 {
  background: url("../img/sprites.png") -10px -242px;
}

/* ***** register ****** */
.register {
  background: url("../img/bg.jpg") top center no-repeat fixed #350404;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
  padding-bottom: 10px;
}
.register .welcome img {
  max-width: 100%;
}
.register .register-container {
  background-color: #faeeee;
  border: 5px solid #ca0000;
  margin-top: -3px;
}
.register .register-container .reg-steps {
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #efe8e8;
}
.register .register-container .reg-steps:after {
  content: "";
  display: table;
  clear: both;
}
.register .register-container .reg-steps .reg-steps-box {
  display: inline-block;
  vertical-align: middle;
  width: 33.3333333333%;
  text-align: center;
  border: 1px solid #c2c2c2;
  line-height: 32pt;
  float: left;
  box-sizing: border-box;
  position: relative;
}
.register .register-container .reg-steps .reg-steps-box.red {
  border: 1px solid #eeb958 !important;
  background: rgb(144, 19, 19);
  background: linear-gradient(to right, rgb(144, 19, 19) 0%, rgb(156, 46, 46) 100%);
}
.register .register-container .reg-steps .reg-steps-box:not(:first-child) {
  border-left: 0;
}
.register .register-container .reg-steps .reg-steps-box:not(:last-child) {
  border-left: 0;
}
.register .register-container .reg-steps .reg-steps-box:not(:last-child):before {
  content: "";
  width: 17px;
  height: 30px;
  position: absolute;
  background-color: #efe8e8;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 6px;
  right: -18px;
  border: 1px solid #c2c2c2;
  border-left: none;
}
.register .register-container .reg-steps .reg-steps-box:not(:last-child):after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid #978e8e;
  border-top: 6px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid transparent;
  top: 16px;
  right: -15px;
}
.register .register-container .reg-steps .reg-steps-box.red:not(:last-child):before {
  content: "";
  width: 17px;
  height: 30px;
  position: absolute;
  background-color: #9c2e2e;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 6px;
  right: -17px;
  border: 1px solid #eeb958;
  border-left: none;
}
.register .register-container .reg-steps .reg-steps-box.red:not(:last-child):after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid #fff;
  border-top: 6px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid transparent;
  top: 16px;
  right: -15px;
}
.register .register-container .reg-steps .reg-steps-box .steps-no {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: #978e8e;
}
.register .register-container .reg-steps .reg-steps-box .steps-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 10.5pt;
  color: #978e8e;
}
.register .register-container .reg-steps .reg-steps-box .steps-no.red,
.register .register-container .reg-steps .reg-steps-box .steps-title.red {
  color: #fff;
}
.register .register-container .register-form {
  padding: 50px 0 30px 0;
}
.register .register-container .register-form .main {
  width: 100%;
  box-sizing: border-box;
}
.register .register-container .register-form .main .row {
  height: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.register .register-container .register-form .main .row .text {
  color: #e72e31;
  float: left;
  width: 200px;
  line-height: 33px;
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 14px;
}
.register .register-container .register-form .main .row .inp {
  float: left;
  width: 290px;
  position: relative;
}
.register .register-container .register-form .main .row .inp input {
  display: block;
  width: 290px;
  height: 33px;
  border: 1px solid #978e8e;
  border-radius: 4px;
  background: transparent;
  box-sizing: border-box;
  outline: none;
  text-indent: 10px;
}
.register .register-container .register-form .main .row .tip {
  line-height: 26px;
  display: flex;
  font-size: 12px;
  color: #000;
  padding-left: 20px;
}
.register .register-container .register-form .main .row button[type=submit] {
  background-image: linear-gradient(#ea4c4c, #c52323);
  border: 0;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 12px;
  padding: 8px;
  width: 130px;
}
.register .register-container .register-form .main .Success .icon img {
  display: block;
  margin: 0 auto;
}
.register .register-container .register-form .main .Success h2 {
  line-height: 120px;
  text-align: center;
  font-size: 42px;
  color: #3e3d3d;
}
.register .register-container .register-form .main .Success ul {
  text-align: center;
}
.register .register-container .register-form .main .Success li {
  display: inline-block;
  color: #648fc5;
  font-size: 16px;
}
.register .register-container .register-form .main .Success li a {
  color: #648fc5;
  font-size: 16px;
}

/* ***** app ****** */
.app {
  background: url("../img/app_bg.jpg") top center no-repeat fixed #ca2a1b;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
}
.app .slogan {
  padding-top: 40px;
  padding-bottom: 10px;
  text-align: center;
}
.app .slogan img {
  max-width: 100%;
}
.app .app-wrapper {
  display: flex;
  align-items: center;
}
.app .app-wrapper .ios-col,
.app .app-wrapper .android-col {
  flex-basis: 16%;
  height: 200px;
  width: 163px;
  position: relative;
}
.app .app-wrapper .ios-col .qr-code-wrapper,
.app .app-wrapper .android-col .qr-code-wrapper {
  position: absolute;
  top: 10px;
  text-align: center;
  width: 100%;
}
.app .app-wrapper .car-col {
  flex-basis: 60%;
}
.app .app-wrapper .car-col .img-car-wrapper {
  position: relative;
}
.app .app-wrapper .car-col .img-car-wrapper .img-car {
  width: 600px;
}
.app .app-wrapper .car-col .img-car-wrapper .img-car-logo {
  position: absolute;
  left: 282px;
  top: 66px;
  width: 80px;
}

/* ***** faqs ****** */
.faq {
  background: url("../img/bg.jpg") top center no-repeat fixed #350404;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
}
.faq .welcome img {
  max-width: 100%;
}
.faq .faq-wrapper {
  display: flex;
  margin-top: -3px;
}
.faq .faq-wrapper .faq-menu {
  flex-basis: 15%;
  margin-right: 6px;
}
.faq .faq-wrapper .faq-menu span,
.faq .faq-wrapper .faq-menu a {
  background-color: #ca0000;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  text-align: center;
  margin-bottom: 6px;
  padding: 15px;
}
.faq .faq-wrapper .faq-menu span.active,
.faq .faq-wrapper .faq-menu a.active {
  background-color: #ffd76b;
  color: #000;
}
.faq .faq-wrapper .faq-content-box {
  flex-basis: 85%;
  background-color: #ca0000;
  color: #fff;
  margin-bottom: 10px;
  padding: 15px;
}
.faq .faq-wrapper .faq-content-box a {
  color: #fff000;
}
.faq .faq-wrapper .faq-content-box h2 {
  color: #fff728;
}
.faq .faq-wrapper .faq-content-box p {
  margin-top: 0;
  margin-bottom: 5px;
}
.faq .faq-wrapper .faq-content-box .relative {
  position: relative;
}
.faq .faq-wrapper .faq-content-box .applogo {
  position: absolute;
  border-radius: 6px;
  padding: 2px;
}
.faq .faq-wrapper .faq-content-box .applogo.logo1 {
  left: 12px;
  top: 20px;
  height: 36px;
  width: 36px;
}
.faq .faq-wrapper .faq-content-box .applogo.logo2 {
  left: 500px;
  top: 142px;
  height: 17px;
  width: 17px;
}
.faq .faq-wrapper .faq-content-box .applogo.logo3 {
  left: 14px;
  top: 25px;
  height: 23px;
  width: 23px;
}
.faq .faq-wrapper .faq-content-box .applogo.logo4 {
  left: 505px;
  top: 25px;
  height: 23px;
  width: 23px;
}
.faq .faq-wrapper .faq-content-box .webname {
  position: absolute;
  color: #000;
}
.faq .faq-wrapper .faq-content-box .webname.name1 {
  color: #6a6a6a;
  font-size: 0.7rem;
  left: 12px;
  top: 60px;
}
.faq .faq-wrapper .faq-content-box .webname.name2 {
  font-size: 0.8rem;
  left: 525px;
  top: 144px;
}
.faq .faq-wrapper .faq-content-box .webname.name3 {
  left: 44px;
  top: 28px;
}
.faq .faq-wrapper .faq-content-box .webname.name4 {
  left: 535px;
  top: 28px;
}

/* ***** promo ****** */
.promo {
  background: url("../img/promo_bg.jpg") top center no-repeat fixed #c80000;
  box-sizing: border-box;
  min-height: calc(100vh - 134px - 80px);
  padding: 20px 0;
}
.promo .welcome {
  text-align: center;
}
.promo .promo-wrapper {
  background-color: #ca0000;
  color: #fff;
  padding: 10px;
  margin-top: -3px;
}
.promo .promo-wrapper .icon {
  cursor: pointer;
}
.promo .promo-wrapper .icon img {
  width: 100%;
}
.promo .promo-wrapper a {
  color: #fff000;
}

.cooperation-list {
  display: flex;
}
.cooperation-list > div {
  text-align: left;
}
.cooperation-list .cooperation-title {
  flex-basis: 150px;
  font-weight: bold;
}
.cooperation-list .cooperation-title:after {
  content: ":";
}
.cooperation-list .cooperation-qrcode {
  width: 150px;
  background: white;
}